import React from "react";
import {
  FaGitlab,
  FaDev,
  FaLinkedin,
  FaTwitter
} from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const siteConfig = {
  copyright: `Copyright © ${new Date().getFullYear()} Muhammad Ahmad. All Rights Reserved.`,
  author: {
    name: "Tijmen de Jong",
    accounts: [
      {
        url: "https://gitlab.com/tijmenndejong",
        label: "Gitlab Account",
        type: "gray",
        icon: <FaGitlab />
      },
      {
        url: "https://twitter.com/tijmen_de_jong",
        label: "Twitter Account",
        type: "twitter",
        icon: <FaTwitter />
      },
      {
        url: "https://dev.to/tijmen_de_jong",
        label: "Dev Account",
        type: "gray",
        icon: <FaDev />
      },
      {
        url: "https://www.linkedin.com/in/tijmen-de-jong-719045137/",
        label: "LinkedIn Account",
        type: "linkedin",
        icon: <FaLinkedin />
      },
      {
        url: "mailto:tijmenndejong@gmail.com",
        label: "Mail tijmen",
        type: "gray",
        icon: <FiMail />
      }
    ]
  }
};

export default siteConfig;
