import ScoreMediaLogo from "assets/images/logos/scoremedia.png";
import RecognizeLogo from "assets/images/logos/recognize.png";
import LandstedeLogo from "assets/images/logos/landstede.png";
import SaxionLogo from "assets/images/logos/saxion.jpg";
import Code14Logo from "assets/images/logos/code14.png";
import FortesLogo from "assets/images/logos/fortes.png";
import ModditLogo from "assets/images/logos/moddit.jpeg";

import figma from "assets/images/skills/figma.png";
import vscode from "assets/images/skills/vscode.png";
import react from "assets/images/skills/react.png";
import javascript from "assets/images/skills/javascript.png";
import typescript from "assets/images/skills/typescript.png";
import html5 from "assets/images/skills/html-5.png";
import css3 from "assets/images/skills/css3.png";
import bootstrap from "assets/images/skills/bootstrap.png";
import mysql from "assets/images/skills/mysql.png";
import heroku from "assets/images/skills/heroku.png";
import nginx from "assets/images/skills/nginx.png";
import netlify from "assets/images/skills/netlify.jpg"
import intellij from "assets/images/skills/intellij.png"
import phpstorm from "assets/images/skills/phpstorm.png";
import vuejs from "assets/images/skills/vuejs.png";
import webstorm from "assets/images/skills/webstorm.png";
import laravel from "assets/images/skills/laravel.png";
import php from "assets/images/skills/php.png";
import java from "assets/images/skills/java.png";

export const companies = [
  {
    title: "Moddit digital agency",
    alt: "Moddit logo",
    role: "Parttime - Full Stack Developer",
    skills: ["php", "laravel", "nuxt JS", "vue JS", "scrum", "git", "vscode"],
    period: "jan. 2022 - now",
    logo: ModditLogo
  },
  {
    title: "Fortes",
    alt: "Fortes logo",
    role: "Stagiair - Full Stack Developer",
    skills: ["react JS", "scrum", "webstorm"],
    period: "aug. 2021 - jan. 2022",
    logo: FortesLogo
  },
  {
    title: "Code14",
    alt: "Code14 logo",
    role: "Parttime - Full Stack Developer",
    skills: ["php", "laravel", "nuxt JS", "vue JS", "figma", "scrum", "phpstorm"],
    period: "dec. 2019 - aug. 2021",
    logo: Code14Logo
  },
  {
    title: "Recognize",
    alt: "Recognize logo",
    role: "Stagiair - Full Stack Developer",
    skills: ["javascript", "java", "angular", "intellij", "scrum"],
    period: "nov. 2018 - jun. 2019",
    logo: RecognizeLogo
  },
  {
    title: "Score Media",
    alt: "Score Media logo",
    role: "Stagiair - App Developer",
    skills: ["android", "java", "android studio"],
    period: "sep. 2017 - dec. 2017",
    logo: ScoreMediaLogo
  },
];

export const institutes = [
  {
    short_title: "Saxion University",
    title: "Saxion University of Applied Sciences",
    role: "Bachelor's Degree in Software Engineering",
    skills: ["project management", "web development", "app development", "data structures", "testing", "devtools"],
    period: "2019 - 2023",
    startingYear: "2019",
    alt: "Saxion image",
    logo: SaxionLogo,
  },
  {
    short_title: "Landstede college",
    title: "Landstede College",
    role: "Software Developer",
    skills: ["web development", "app development"],
    period: "2016 - 2019",
    startingYear: "2016",
    alt: "Landstede logo",
    logo: LandstedeLogo,
  }
];

export const skills = [
  {
    name: "React",
    description: "Web development",
    link: "https://reactjs.org/",
    type: "development",
    image: react
  },
  {
    name: "Javascript",
    description: "Web development",
    link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    type: "development",
    image: javascript
  },
  {
    name: "Java",
    description: "Language",
    link: "",
    type: "development",
    image: java,
  },
  {
    name: "PHP",
    description: "Language",
    link: "",
    type: "development",
    image: php
  },
  {
    name: "Laravel",
    description: "Web development",
    link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    type: "development",
    image: laravel,
  },
  {
    name: "Typescript",
    description: "Web development",
    link: "https://www.typescriptlang.org/",
    type: "development",
    image: typescript
  },
  {
    name: "Vue JS",
    description: "Web development",
    link: "https://www.vuejs.org/",
    type: "development",
    image: vuejs
  },
  {
    name: "Html5",
    description: "Web layouts",
    link: "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5",
    type: "development",
    image: html5
  },
  {
    name: "Css3",
    description: "Web design",
    link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    type: "design",
    image: css3
  },
  {
    name: "Bootstrap",
    description: "Web design",
    link: "https://getbootstrap.com/",
    type: "design",
    image: bootstrap
  },
  {
    name: "Mysql",
    description: "Database",
    link: "https://www.mysql.com/",
    type: "database",
    image: mysql
  },
  {
    name: "Heroku",
    description: "Devops",
    link: "https://www.heroku.com/",
    type: "web server",
    image: heroku
  },
  {
    name: "Nginx",
    description: "Web server",
    link: "https://www.nginx.com/",
    type: "devops",
    image: nginx
  },
  {
    name: "Netlify",
    description: "Web server",
    link: "https://www.netlify.com/",
    type: "devops",
    image: netlify
  },
  {
    name: "Intellij",
    description: "Code editor",
    link: "https://www.jetbrains.com/idea/",
    type: "development",
    image: intellij
  },
  {
    name: "Webstorm",
    description: "Code editor",
    link: "https://www.jetbrains.com/webstorm/",
    type: "development",
    image: webstorm
  },
  {
    name: "PHP Storm",
    description: "Code editor",
    link: "https://www.jetbrains.com/phpstorm/",
    type: "development",
    image: phpstorm
  },
  {
    name: "Visual Studio Code",
    description: "Code editor",
    link: "https://code.visualstudio.com/",
    type: "development",
    image: vscode
  },
  {
    name: "Figma",
    description: "Collaborative Design",
    link: "https://www.figma.com/",
    type: "design",
    image: figma
  }
];
