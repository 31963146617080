import deFabriekLogo from "assets/images/projects/svdefabriek.png";
import recognizeLogo from "assets/images/logos/recognize.png";
import modditLogo from "assets/images/logos/moddit.jpeg";

export const projectsList = [
  {
    id: 1,
    title: "Moddit Finance API - Moddit Digital Agency",
    logo: modditLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `A API interface build for Moddit Internal Finance connections.`,
    technologies: ["php", "laravel", "vscode", "lighthouse", "graphql"]
  },
  {
    id: 2,
    title: "Mijn Moddit - Moddit Digital Agency",
    logo: modditLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `A web application build for Moddit Intern.`,
    technologies: ["php", "laravel", "vue", "vscode"]
  },
  {
    id: 3,
    title: "Management Tool - SV de Fabriek",
    logo: deFabriekLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `A web application build for SV de Fabriek.`,
    technologies: ["php", "laravel", "vue", "vscode"]
  },
  {
    id: 4,
    title: "Website - SV de Fabriek",
    logo: deFabriekLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://defabriek.org/",
    desc: `A web application build for showing information about Skatepark de Fabriek.`,
    technologies: ["php", "laravel", "vue", "webstorm"]
  },
  {
    id: 5,
    title: "API - SV de Fabriek",
    logo: deFabriekLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `A api application build for retrieving data of SV de Fabriek.`,
    technologies: ["php", "laravel", "phpstorm"]
  },
  {
    id: 6,
    title: "Android App - Recognize",
    logo: recognizeLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `A android app build for Recognize.`,
    technologies: ["java", "maven", "android studio"]
  },
];
